@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Ms+Madi&display=swap");

html,
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Caveat", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	width: 100%;
	height: 100%;
	overflow: hidden;
	overscroll-behavior-x: none;
}

body > div {
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

p {
	font-family: "Caveat", cursive;
	font-size: 16px;
	font-size: 4vw;
	font-weight: 700;
	padding: 0px;
	margin: 0px;
}
