/* .blur {
	transition: 1s filter linear;
	-webkit-transition: 1s -webkit-filter linear;
	-moz-transition: 1s -moz-filter linear;
	-ms-transition: 1s -ms-filter linear;
	-o-transition: 1s -o-filter linear;

	filter: blur(8px);
	-webkit-filter: blur(8px);
}
.noblur {
	filter: blur(0px);
	-webkit-filter: blur(0px);
} */

.blurin {
	-moz-animation: blurin 1s;
	-webkit-animation: blurin 1s;
	animation: blurin 3s ease forwards;
	animation-iteration-count: 1;
}

@-moz-keyframes blurin {
	0% {
		-moz-filter: blur(0px);
	}

	100% {
		-moz-filter: blur(10px);
	}
}

@-webkit-keyframes blurin {
	0% {
		-webkit-filter: blur(0px);
	}

	100% {
		-webkit-filter: blur(10px);
	}
}

@keyframes blurin {
	0% {
		filter: blur(0px);
	}

	100% {
		filter: blur(10px);
	}
}

.blurloop {
	-moz-animation: blurloop 10s;
	-webkit-animation: blurloop 10s;
	animation: blurloop 10s ease infinite;
}

@-moz-keyframes blurloop {
	0% {
		-moz-filter: blur(0px);
	}

	50% {
		-moz-filter: blur(10px);
	}

	100% {
		-moz-filter: blur(0px);
	}
}

@-webkit-keyframes blurloop {
	0% {
		-webkit-filter: blur(0px);
	}

	50% {
		-webkit-filter: blur(10px);
	}

	100% {
		-webkit-filter: blur(0px);
	}
}

@keyframes blurloop {
	0% {
		filter: blur(0px);
	}

	50% {
		filter: blur(10px);
	}

	100% {
		filter: blur(0px);
	}
}

.blur {
	filter: blur(10px);
	-webkit-filter: blur(10px);
	-moz-filter: blur(10px);
}
